import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './Cards.scss'
import CategoryPageLayout from '../CategoryPageLayout/CategoryPageLayout'
// import { CategoryFeatureProps } from '../CategoryFeatures/CategoryFeature'
import { CategoryCardProps } from '../GamesCategories/CategoryCard'
import GameCard from '../GameCard/GameCard'
// import { Breadcrumb } from '../../Breadcrumbs/Breadcrumbs'
import CardsInfo from '../CategoryAndGamesInfo/CardsInfo'
import DesktopLarge from '../GameCard/DesktopLarge'
import useShowAll from '../../../hooks/useShowAll'
import useTestimonials from '../../Testimonials/useTestimonials'
import { cardsFaqs } from '../../FAQs/faqs-list'
import { POKER, RUMMY } from '../../internal-links'
import skills from '../game-skills'
import gameDescription from '../games-description'

const Cards: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "cards-intro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      f1: file(relativePath: { eq: "cards-feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f2: file(relativePath: { eq: "cards-feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f3: file(relativePath: { eq: "cards-feature3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      casual: file(relativePath: { eq: "casual.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      trivia: file(relativePath: { eq: "trivia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      Poker: file(
        relativePath: { eq: "Mobile Poster Assets/TEXAS_YOUTUBE Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Rummy: file(
        relativePath: { eq: "Mobile Poster Assets/preview copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      PokerL: file(relativePath: { eq: "Desktop poster assets/hhhh.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      RummyL: file(
        relativePath: { eq: "Desktop poster assets/_RummyBanner_.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      thPoker: file(
        relativePath: { eq: "thumbnails/Verticle_TexasHoldem.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      thRummy: file(relativePath: { eq: "thumbnails/Rummy-Thumbnails.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  // const features: CategoryFeatureProps[] = [
  //   {
  //     title: 'Experience smooth gaming & amazing visuals',
  //     imgSrc: data.f1.childImageSharp.fluid,
  //     numberColor: '#37D6C2',
  //   },
  //   {
  //     title: 'Take smart decisions with in-game tips & tricks',
  //     imgSrc: data.f2.childImageSharp.fluid,
  //     numberColor: '#FF3D3C',
  //   },
  //   {
  //     title: 'Rely on our honest game engine with certified RNG',
  //     imgSrc: data.f3.childImageSharp.fluid,
  //     numberColor: '#2270DA',
  //   },
  // ]

  const otherCategories: CategoryCardProps[] = [
    {
      category: 'casual',
      imgSrc: data.casual.childImageSharp.gatsbyImageData,
      description: 'Cool games like Pool & Carrom',
    },
    {
      category: 'trivia',
      imgSrc: data.trivia.childImageSharp.gatsbyImageData,
      description: 'Fun questions in GK, 123, PicMe',
    },
  ]

  // const breadcrumbs: Breadcrumb[] = [
  //   {
  //     title: 'All Games',
  //     url: '/games',
  //   },
  //   {
  //     title: 'Cards',
  //     url: '',
  //   },
  // ]

  const isDesktop = useShowAll()
  const { cardsTestimonials } = useTestimonials()

  return (
    <div id="cards-category">
      <CategoryPageLayout
        pageTitle="Real fun is playing cards with real money!"
        // features={features}
        // featuresTitle="Experience our seamless game design"
        otherCategories={otherCategories}
        // breadcrumbs={breadcrumbs}
        introThumbnail={data.intro.childImageSharp.gatsbyImageData}
        livePlayersText={
          <>
            Players playing Cards on <span className="underline">Mega</span>{' '}
            right now!
          </>
        }
        CategoryInfo={<CardsInfo />}
        testimonials={cardsTestimonials}
        faqs={cardsFaqs}
      >
        <div className="m-card">
          <GameCard
            category="cards"
            showCategory
            name="Hold 'em Poker"
            showName
            description={gameDescription.Poker.long}
            showDescription
            gameImage={data.Poker.childImageSharp.gatsbyImageData}
            gameUrl={POKER}
            trailerVideo="https://player.vimeo.com/video/475786286?title=0&byline=0&portrait=0&autoplay=1&background=0"
          />
        </div>
        <div className="m-card">
          <GameCard
            category="cards"
            showCategory
            name="Rummy"
            showName
            description={gameDescription.Rummy.long}
            showDescription
            gameImage={data.Rummy.childImageSharp.gatsbyImageData}
            trailerVideo="https://player.vimeo.com/video/475786338?title=0&byline=0&portrait=0&autoplay=1&background=0"
            gameUrl={RUMMY}
          />
        </div>

        {isDesktop ? (
          <div className="d-cards">
            <div className="d-card d-card-full">
              <DesktopLarge
                category="cards"
                showCategory
                name="Hold 'em Poker"
                showName
                description={gameDescription.Poker.long}
                showDescription
                gameImage={data.PokerL.childImageSharp.gatsbyImageData}
                gameThumbnail={data.thPoker.childImageSharp.gatsbyImageData}
                gameUrl={POKER}
                trailerVideo="https://player.vimeo.com/video/475786286?title=0&byline=0&portrait=0&autoplay=1&background=0"
                skills={skills.Poker}
              />
            </div>
            <div className="d-card d-card-full mt-36">
              <DesktopLarge
                category="cards"
                showCategory
                name="Rummy"
                showName
                description={gameDescription.Rummy.long}
                showDescription
                gameImage={data.RummyL.childImageSharp.gatsbyImageData}
                gameThumbnail={data.thRummy.childImageSharp.gatsbyImageData}
                gameUrl={RUMMY}
                trailerVideo="https://player.vimeo.com/video/475786338?title=0&byline=0&portrait=0&autoplay=1&background=0"
                skills={skills.Rummy}
              />
            </div>
          </div>
        ) : null}
      </CategoryPageLayout>
    </div>
  )
}

export default Cards
