import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CardsCategory from '../../components/Games/Cards/Cards'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { GAMES, CARDS as CARDS_LINK } from '../../components/internal-links'
import { CARDS } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generatePokerTrailerSchema,
  generatePokerReviewSchemaAndroid,
  generateRummyTrailerSchema,
  generatePokerReviewSchemaIos,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Cards',
    url: CARDS_LINK,
  },
]

const Cards: React.FC = () => {
  const [cardsFaqsSt, setCardsFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ cardsFaqs }) =>
      setCardsFaqsSt(cardsFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={CARDS}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Card Games: Play Online Card Games for Cash Money Upto 1 Cr | Mega"
        description="Experience the most exciting real money card games on Mega - India’s hottest real money gaming platform. Play Poker or Rummy & win up to ₹5 lakhs in your 1st week."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(cardsFaqsSt)}
        videoSchema={[
          generatePokerTrailerSchema(),
          generateRummyTrailerSchema(),
        ]}
      />
      <CardsCategory />
    </Layout>
  )
}

export default Cards
