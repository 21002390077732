import { Link } from 'gatsby'
import React from 'react'
import { DOWNLOAD } from '../../internal-links'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const CardsInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn about Card Games</h2>
      <p>
        Whether it’s a family gathering, a wedding or just another evening with
        friends, when is a game of cards not on the cards?
      </p>
      <p>
        The 52 cards across 4 suits of a classic French deck give us lots of fun
        and challenging games. From casino-favourite real money card games like
        Blackjack to the classic Solitaire pre-loaded in computers, most card
        games can be broadly categorised under the following types:
      </p>
      <p>
        <strong>Matching Games</strong> want you to make a particular set of
        matching cards before anyone else on the table to win. In a matching
        game like Rummy, this can be done by picking a card from the pile &
        disposing of another, in each turn.
      </p>
      <p>
        <strong>Comparing or Showdown Games</strong> decide the winner by the
        value of their hand. Poker, Blackjack, Baccarat, Teen Patti are some of
        the most popular real money card games.
      </p>
      <p>
        <strong>Trick-Taking Games</strong> are about winning a pre-decided
        number of tricks. in a number of rounds. e.g. Bridge, Spades
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        <strong>Shedding Games</strong> such as Switch or Crazy Eights, are all
        about losing cards. You have to discard the hand you have been dealt the
        fastest.
      </p>
      <p>
        <strong>Accumulating Games</strong> such as Slackjack, on the other
        hand, want you to collect all the cards in your hand the fastest.
      </p>
      <p>
        <strong>Fishing Games</strong> such as GoFish combine the features of
        matching & shedding games. These require players to &lsquo;fish&rsquo;
        for cards - by taking them from another player&apos;s hand, asking them
        for cards, or taking from a pile.
      </p>
      <p>
        <strong>Patience or Solitaire Games</strong> are designed specially for
        single players. Patience games like Freecell and Pyramid, are all about
        making a particular formation of cards from a given set.
      </p>
      <p>
        Different games, different challenges. With the internet in every
        pocket, the excitement of mastering new games is now a few taps away.
        Challenge and defeat real players, to show off your hidden skills.
        Discover the joy of card games, with the thrill of real money.
      </p>
      <p>
        <Link to={DOWNLOAD}>Download Mega</Link>, and experience real money card
        games online like never before
      </p>
      <p>
        Are you an all-new cards fan, a regular player or a seasoned
        professional? Regardless of your experience, we have the perfect game
        for you. Find the right tables and contests to fit your skills and
        ambitions, and win real cash with card games, only at Mega.
      </p>

      <ul>
        <li>
          <p>Poker</p>
        </li>
        <li>
          <p>Rummy</p>
        </li>
      </ul>

      <h3>Poker</h3>
      <p>
        Winning Poker is all about having the best hand possible, or convincing
        the other player that you do.
      </p>
      <p>
        The showdown card game of Poker is the world&apos;s most popular, and
        the internet’s favourite online card game played for money. With its
        perfect blend of skill, probability and strategy, Poker is a rewarding
        experience for players at any level of expertise.
      </p>
      <p>
        Mega offers the standard No-Limit Texas Hold’em Poker. With Big Blinds
        ranging from ₹0.1 to ₹200 and beyond, we always have the perfect table
        for you.
      </p>
      <p>Play Poker on Mega. Download now.</p>

      <h3>Rummy</h3>
      <p>
        Rummy is India&apos;s favourite real money card game — perfect for all
        occasions. Rummy has you thinking on your feet — continuously working on
        forming the perfect sets and sequences from the given set of cards,
        before anyone else on the table.
      </p>
      <p>
        A perfect mix of strategy and quick decision-making, Rummy provides a
        fun, fast-paced challenge and ample rewards for those with a sharp eye
        for patterns.
      </p>
      <p>
        With minimum buy-ins of as chill as ₹1 to as grand as ₹4000 and beyond,
        Mega Rummy is the standard 13-card Indian Rummy with 2 decks.
      </p>
      <p>Play Rummy on Mega. Download now.</p>
    </>
  )

  return (
    <div className="category-info">
      <ReadMoreLessSections visible={visible} hidden={hidden} />
    </div>
  )
}

export default CardsInfo
